import './App.css'

function App() {
  return (
    <div className='App'>
      <h1 className='App-header'>This is the florenciapiola app</h1>
    </div>
  )
}

export default App
